import React from "react";
import Layout from "../../components/Core/Layout";
import { DefaultPageTemplate } from "../../templates/default";

// eslint-disable-next-line
export default () => (
  <Layout>
    <DefaultPageTemplate 
      title="Merci beaucoup pour votre message!"
      content="Nous revenons vers vous dans les plus brefs délais pour votre courriel sur le coaching professionnel avec Allô Anaïs."
    />
  </Layout>
);

export { Head } from "../../components/Core/HtmlHead";